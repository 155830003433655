import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { WarmTransferState } from '../../../app.interface';
import { CALL_TRANSFER_STATUS, SUPPORTED_STATES, WarmTransferEvent, WTE_HISTORY_STATUSES } from 'common.interfaces';
import {
  getAdvocatesWithInternalWtSkill,
  getIncomingWarmTransferInLicensedStatesAndSkill,
  getOnlineWtAdvocates,
  getSortedAvailableAdvocatesPerStates,
  getSortedHistory,
  getTransferredWTCount,
  getUpdatedIncomingWtsAndHistoryAfterWtEvent,
  getNonExpiredIncomingTransfers,
  getAvailableWtAdvocates,
  getInboundCalls,
  getNotInProgressWTs,
  getConfirmedWtsAvailable,
  getInboundCallsAvailable,
} from './warm-transfer.store.utils';
import { computed, inject } from '@angular/core';
import { UserStore } from '../user/user.store';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, catchError, filter, interval, pipe, switchMap, tap } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '@origin8-web/core-ui/notification';
import { WarmTransferService } from '../../../service-gateways/warm-transfer/warm-transfer.service-gateway';
import { tapResponse } from '@ngrx/operators';
import { LiveStatusStore } from '../live-status/live-status.store';
import { SnackMessageService } from '@origin8-web/core-ui/snack';
import { fp } from '@origin8-web/o8-utils/fp';

const FIVE_SECONDS = 5000; /* In ms */
const ONE_SECOND_AND_HALF = 1500; /* In ms */

const initialState: WarmTransferState = {
  active: false,
  muted: true,
  notifiedWteAni: [],
  history: [],
  incoming: [],
  loadingAvailableAdvocates: true,
  loadingHistory: true,
  displayHistoryPanel: true,
  liveInboundCalls: [],
};

export const WarmTransferStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store, liveStatusStore = inject(LiveStatusStore)) => {
    return {
      workingWtAdvocates: computed(() =>
        getOnlineWtAdvocates(liveStatusStore.representativesProfiles(), liveStatusStore.representativeStatuses()),
      ),
      wtAdvocatesInAvailableStatus: computed(() =>
        getAvailableWtAdvocates(liveStatusStore.representativesProfiles(), liveStatusStore.representativeStatuses()),
      ),
      displayedHistory: computed(() =>
        getSortedHistory(store.history().filter((r) => WTE_HISTORY_STATUSES.some((s) => s === r.callTransferStatus))),
      ),
    };
  }),
  withComputed((store, userStore = inject(UserStore)) => {
    return {
      incomingWarmTransferAvailableForAdvocate: computed(() => {
        return getIncomingWarmTransferInLicensedStatesAndSkill(
          userStore.wtLicensedStates(),
          userStore.wtSkills(),
          store.incoming(),
          store.wtAdvocatesInAvailableStatus(),
        );
      }),
      currentUserWorking: computed(() => {
        return store.workingWtAdvocates().some((a) => a.email === userStore.userInfo()?.email);
      }),
      warmTransferHistoryCount: computed(() => store.displayedHistory().length),
      warmTransferTransferredCount: computed(() => getTransferredWTCount(store.displayedHistory())),
      availableAdvocatesPerStates: computed(() =>
        getSortedAvailableAdvocatesPerStates(SUPPORTED_STATES, store.wtAdvocatesInAvailableStatus()),
      ),
      availableAdvocateForInternalWt: computed(() => {
        const internalWtAdvocates = getAdvocatesWithInternalWtSkill(store.workingWtAdvocates());
        return getSortedAvailableAdvocatesPerStates(SUPPORTED_STATES, internalWtAdvocates);
      }),
    };
  }),
  withComputed((store) => {
    return {
      confirmedWtsAvailable: computed(() => {
        return getConfirmedWtsAvailable(store.incomingWarmTransferAvailableForAdvocate(), store.liveInboundCalls());
      }),
      inboundCallsAvailable: computed(() => {
        return getInboundCallsAvailable(store.incomingWarmTransferAvailableForAdvocate(), store.liveInboundCalls());
      }),
    };
  }),
  withComputed((store) => {
    return {
      unNotifiedInboundCalls: computed(() => {
        return store
          .inboundCallsAvailable()
          .filter((ic) => {
            return !store.notifiedWteAni().some((nw) => nw === ic.callInfo.contact_info);
          })
          .map((ic) => ic.callInfo.contact_info);
      }),
    };
  }),
  withMethods((store, titleService = inject(Title), notificationService = inject(NotificationService)) => {
    return {
      setIncoming: (incoming: WarmTransferEvent[]) => patchState(store, { incoming }),
      setMuteState: (muted: boolean) => patchState(store, { muted }),
      setActiveState: (active: boolean) => patchState(store, { active, muted: false }),
      toggleHistoryPanel: () => patchState(store, { displayHistoryPanel: !store.displayHistoryPanel() }),
      flashPageTitleOnIncomingTransfers: rxMethod<WarmTransferEvent[]>(
        pipe(
          switchMap(() => {
            const defaultTitle = 'Protec8';
            const incomings = [...store.confirmedWtsAvailable(), ...store.inboundCallsAvailable()];
            if (incomings.length === 0 || !store.active()) {
              titleService.setTitle(defaultTitle);
              return EMPTY;
            }
            const pageTitle = `(${incomings.length}) WT incoming | Protec8`;
            let title: string = pageTitle;
            titleService.setTitle(`(${incomings.length}) WT incoming | Protec8`);
            return interval(ONE_SECOND_AND_HALF).pipe(
              tap(() => {
                title = title === defaultTitle ? pageTitle : defaultTitle;
                titleService.setTitle(title);
              }),
            );
          }),
        ),
      ),
      notifyUserForIncomingTransfers: rxMethod<string[]>(
        pipe(
          filter((unNotifiedAni) => unNotifiedAni.length > 0),
          tap((unNotifiedAni) => {
            const notifiedWteAni = [...unNotifiedAni, ...store.notifiedWteAni()];
            patchState(store, { notifiedWteAni });
          }),
          switchMap((unNotifiedAni) => {
            if (!store.active() || store.muted() || unNotifiedAni.length === 0) {
              return EMPTY;
            }
            return notificationService
              .displayNotification('Urgent Incoming WTE. Pick it up if you can!', {
                body: `${unNotifiedAni.length} WT in waiting in queue. Make yourself available if you can!`,
                silent: !store.currentUserWorking(),
                tag: `${unNotifiedAni.join('-')}`,
              })
              .pipe(
                catchError((e) => {
                  console.error('An error occured while trying to display the notification', e);
                  return EMPTY;
                }),
              );
          }),
        ),
      ),
      cleanUpExpiredWarmTransferEvents: rxMethod<boolean>(
        pipe(
          switchMap((active) => {
            if (!active) {
              return EMPTY;
            }
            return interval(FIVE_SECONDS);
          }),
          tapResponse({
            next: () => {
              const incoming = getNonExpiredIncomingTransfers(store.incoming());
              patchState(store, { incoming });
              console.log('Cleaned up expired WTEs', store.incoming(), store.liveInboundCalls());
            },
            error: (e) => console.error(e),
          }),
        ),
      ),
    };
  }),
  withMethods(
    (
      store,
      warmTransferService = inject(WarmTransferService),
      userStore = inject(UserStore),
      snackMessageService = inject(SnackMessageService),
    ) => {
      return {
        loadWtHistory: rxMethod<void>(
          pipe(
            switchMap(() => {
              return warmTransferService.getWarmTransferHistoryToday();
            }),
            tapResponse({
              next: (history) => {
                patchState(store, { history, loadingHistory: false });
              },
              error: (e) => {
                console.error(`Could not load history of WTE`, e);
                snackMessageService.showError(`An error occured while loading the WT history for today`);
                patchState(store, { loadingHistory: false });
              },
            }),
          ),
        ),
        getWtePickedUpEvents: rxMethod<boolean>(
          pipe(
            switchMap((active) => {
              if (!active) {
                return EMPTY;
              }
              return warmTransferService.getLiveContactEvent();
            }),
            tap((events) => {
              const liveInboundCalls = getInboundCalls(events);
              const incoming = getNotInProgressWTs(events, store.incoming());
              patchState(store, { incoming, liveInboundCalls });
              console.log('Updated live inbound calls', store.incoming(), store.liveInboundCalls(), events);
            }),
          ),
        ),
        getWTEvents: rxMethod<boolean>(
          pipe(
            switchMap((active) => {
              if (!active) {
                return EMPTY;
              }
              return warmTransferService.getWarmTransferEvent();
            }),
            filter((wte) => wte.callTransferStatus.toLowerCase() !== CALL_TRANSFER_STATUS.PENDING.toLowerCase()),
            tap((event) => {
              const { incoming, history } = getUpdatedIncomingWtsAndHistoryAfterWtEvent(
                event,
                userStore.wtLicensedStates(),
                userStore.wtSkills(),
                {
                  incoming: store.incoming(),
                  history: store.history(),
                },
              );
              patchState(store, { incoming, history });
              console.log(
                'get WT events',
                store.incoming(),
                store.liveInboundCalls(),
                fp.pick(event, 'ani', 'callTransferStatus', 'stateFromAd', 'warmTransferSkill'),
              );
            }),
          ),
        ),
      };
    },
  ),
);
